import React from "react"

import MyLayout from "../components/layout"
import SEO from "../components/seo"
import FourZeroFourImage from "../components/404image"
import { Col, Row } from "antd"

const NotFoundPage = () => (
  <MyLayout   >
    <SEO title="404: Page Not found" />
    <div style={{paddingTop: '20vh'}}></div> {/*Could not vertically align, this is a lame attempt*/}
    <p align="center">
      <b>404. </b>That's an error.
    </p>

    <div
      style={{
        margin: "auto", //horizontally align image
        width: "40%", //horizontally align image
      }}
    >
      <FourZeroFourImage />
    </div>

    <p
      align="center"
      style={{
        fontSize: "16px",
      }}
    >
      We could not find that url, sorry.
    </p>
    

  </MyLayout>
)

export default NotFoundPage
